/* TERMS AND CONDITIONS */
div#terms {
  margin-bottom: 80px;
  padding: 40px 80px;
  background-color: #fefefe;
  border-radius: 8px;
  color: #666;

  h1 {
    color: #666;
    font-size: 24px;
  }

  p {
    font-size: 14px;
    margin-bottom: 15px;
  }

  hr {
    border-color: #aaa;
  }

}
