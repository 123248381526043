/* Optin Pages */

div#tutorialModal {

    background-color: transparent;
    text-align: center;

    div.modal-body {

        text-align: center;

        p {
          font-size: 18px;
          color: @smart-blue;
        }

        h2 {
          font-size: 28px;
          color: #333;
          font-weight: 400;
        }

        h2.green {
          font-size: 48px;
          color: #5cb85c;
          font-weight: 700;
        }
      }
}

body.optin {
  background: url('/img/optin-bg.jpg') #3d71af no-repeat;
  background-attachment: scroll !important;
  background-size: contain !important;
  background-position: bottom center;
  padding-bottom: 55px;
  height: auto !important;
  min-height: 100%;

  &.confirm {
    background-size: cover !important;
  }

  p, label {
    color: #fff;
  }

  .optin {

    h5 {
      font-size: 18px;
      font-weight: 300;
      line-height: 1.4em;
    }

    h4 {
      font-size: 22px;
      font-weight: 300;
      line-height: 1.3em;
    }

    h3 {
      margin: 20px 0px 20px 0px;
    }

    h2 {
      margin: 20px 0;
    }

    label {
      font-size: 16px;
      line-height: 20px;
    }

    input {
      font-weight: bold;
    }

    .form-group {
      margin: 0px 0px 30px 0px;
      border-radius: 4px;
    }

    .no-label {
      margin-top: 25px;
    }

    .form-control.inverse {
      background: rgba(0, 0, 0, 0.3);
      border: 1px solid rgba(0, 0, 0, 0.1);
      color: #fff;
    }

    .form-group.has-error {
      padding: 20px;
      border: 2px solid #ccc;
      border-color: @new;

      input {
        border-color: @new;
      }
    }

    .radio {
      margin-top: 0px;
    }

    div.alert {

      background-color: @new;
      border-color: @new;

      h3 {
        margin: 0 0 15px 0;
        font-weight: bold;
        color: white;
      }

      ul {

        padding-left: 20px;

        li {
          color: white;
          font-size: 16px;
          font-weight: bold;
        }
      }

    }

    i.red {
      padding-top: .15em;
      color: @new;
    }

    #transfer-notice {
      display: none;
      margin-top: -20px;
    }

  }

}

.tools {
  h1 {
    font-size: 48px !important;

    .fa {
      margin-right: 10px;
    }
  }
}

.optin-form {
  background-color: rgba(255, 255, 255, .8);
  padding-bottom: 25px;

  label {
    text-align: left !important;
    color: #2c87c9;
    float: left;
  }

  .form-group {
    margin-bottom: 15px;
  }
}


/* DASHBOARD */

div#optin-site-data {
  height: 100%;
  position: relative;
  top: -90px;
  padding: 60px 0px;

  h1 {
    padding: 20px;
    margin-bottom: 90px;
    background-color: rgba(0, 0, 0, 0.3);
    font-weight: 700;
    font-size: 48px;
    color: #fff;
  }

  a:hover {
    text-decoration: none;
  }

  div.logo {
    margin-bottom: 11%;
    background-color: rgba(255, 255, 255, 0.00);
    text-align: center;

    img {
      max-height: 125px;
    }

  }

  div.panel {
    background-color: rgba(255, 255, 255, 0.85);
    text-align: center;
    cursor: pointer;

    h2.panel-title {
      font-size: 18px;
      font-weight: 700;
      color: @smart-blue;
      text-transform: uppercase;
      height: 40px;

      @media screen and (min-width: 1155px) {
        height: 20px;
      }
    }

    div.panel-body {
      padding: 20px inherit;
      color: #666;
      text-shadow: 2px 2px 2px #c0c0c0;
    }
  }

  div.panel:hover {
    background-color: #fff;
    -webkit-box-shadow: 3px 3px 3px 0px rgba(170, 170, 170, .75);
    -moz-box-shadow: 3px 3px 3px 0px rgba(170, 170, 170, .75);
    box-shadow: 3px 3px 3px 0px rgba(170, 170, 170, .75);

    text-decoration: none;
  }

  div.panel-heading {
    background-color: rgba(255, 255, 255, 0.9);
  }

}

div.tools-icons {
  div.modal {
    background-color: transparent;
  }

  div.modal-body {

    text-align: center;

    p {
      font-size: 18px;
      color: @smart-blue;
    }

    h2 {
      font-size: 28px;
      color: #333;
      font-weight: 400;
    }

    h2.green {
      font-size: 48px;
      color: #5cb85c;
      font-weight: 700;
    }
  }

}

div#optin-site-data {
  margin-top: 0;
  top: 0;
  height: auto;
  background: none !important;

  &.background {
    background-color: rgba(255, 255, 255, .7) !important;
    padding: 0;
  }

}

ul#reports {
  margin: 0;
  padding: 0;
}

.tablesorter {
  .header {
    cursor: pointer;
  }
}

#optinarchive {
  width: 100%;
  margin: 0;

  thead {
    th {
      position: relative;
    }
  }

  tbody {
    td {
      background: rgb(234, 234, 234) !important;
      border-bottom: 1px solid rgb(0, 117, 198);
      min-width: 100px;

      .btn {
        float: right;
      }
    }
  }
}

/*
OPT-IN admin pages
*/
.optinStudyDropdown {
  width: 500px;
}

#optin-email-body, #optin-table {
  display: none;
}

//SITE UPLOADER
.dropzone {
  width: 100%;
  padding: 25px;
  border: 2px solid transparent;

  &.over {
    border: 2px dashed #e8e8e8;
  }

  h3 {
    color: #313131;
  }

  p {
    color: #717171;
    text-align: center;
  }

  input[type="file"] {
    display: none;
  }

  i {
    font-size: 35px;
    color: #979797;
  }

  .progress-container {
    display: none;
    margin: 25px 0;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}

.importLog {
  color: #313131;
  margin: 1px 0;
  padding-top: 2px;
  padding-bottom: 0px;
  text-align: left;
  background: #f2f2f2;
  border: 1px solid #e8e8e8;

  &.success {
    background: #dff0d8;
    border: 1px solid #d6e9c6;
  }

  &.danger {
    background: #f2dede;
    border: 1px solid #ebccd1;
  }

  p {
    text-align: left;
    color: #313131;
  }

  .importErrors {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .btn {
    float: right;
  }
}

.tablesorter, #optinarchive {
  &.sorting thead tr th:after {
    background: none;
  }

  thead {
    tr {
      th {
        font-weight: 600;
        cursor: pointer;

        &.no-sort {
          cursor: default;
        }
      }

      th:after {
        content: '';
        width: 11px;
        height: 20px;
        margin-left: 10px;
        position: absolute;
      }

      .no-sort:after {
        background: none;
      }

      .headerSortDown:after {
        background: url('/images/sort-triangles.png') no-repeat !important;
        background-position: -12px 0 !important;
      }

      .headerSortUp:after {
        background: url('/images/sort-triangles.png') no-repeat !important;
        background-position: -23px 0 !important;
      }
    }
  }

  tbody {
    tr:nth-child(even) {
      td {
        background: #f2f2f2;

        &.status {
          background: #fff;
        }
      }
    }

  }

}


alerts {
  .error {
    background: #DE3737;
    border: #F74040 1px solid;
  }

  p {
    height: 35px;
    line-height: 35px;
    color: #fff;
    padding: 0 0 0 13px;
    margin: 5px 0;
    width: 99%;
    text-align: left;
  }
}

// Uploads
#sitelistupload {
  .background {
    padding: 25px;
    background: #fff;
    margin: 10px 0;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    border: 3px solid #e8e8e8;
    margin: 25px 0;
  }

  .actions {
    display: none;
    margin: 10px auto;
  }

  input[type="file"] {
    display: none;
  }
}

.tableHeader {
  padding-top: 10px;
  background: #fff;
  margin: 3px 0;

  p {
    text-align: left;
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 8px;
  }
}

.tableFooter {
  margin-top: 15px;
}

.import-logs {
  background-color: #777;
  padding: 3px 0;
}

.optin-archive {
  background-color: #0087cb;
}

.detailsToggle {
  margin-left: 200px;
  margin-top: 10px;
  color: #0087cb;
}

.detailsToggle:hover {
  text-decoration: underline;
}

.sitelistImportResultDetails {
  display: none;
}

.sitelistImportResultDetails li {
  line-height: 200%;
}

.sitelistImportResultSet {
  margin-bottom: 10px;

  i.green {
    color: #1ece05;
  }

  i.red {
    color: #c80000;
  }
}

.table-filters {
  margin-bottom: 25px;
  padding: 15px;
}
