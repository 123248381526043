/* LOGIN */
div.logo {
  position: relative;
  top: 0px;
  text-align: center;

  img {
    width: 280px;
    padding: 0px;

    @media (min-width: 768px) {
      width: 480px;
    }

  }

}

div#login {
  width: 100%;
  padding: 20px 0px 20px 0px;
  text-align: center;
  /*background-color: rgba(0,0,0,0.6);*/

  @media (min-width: 768px) {
    position: fixed;
    bottom: 38px;
    padding: 20px auto;
  }

  h1 {
    margin-bottom: 1em;
    font-size: 24px;
    font-weight: 700;
  }

  p {
    padding: 0px 10px;
    color: #fff;
    font-size: 18px;
  }

  a {
    color: #fff;
    font-weight: 700;
    font-size: 18px;
  }

  div#logininfo,
  div.loginpanel {
    margin: 0px 0px 20px 0px;
  }

  form {

    .input-group {
      margin-bottom: 20px;
    }

    .form-group {
      margin-bottom: 10px;
    }

    label {
      margin: 10px 0 12px;
      font-size: 24px;
      color: #fff;

      @media (min-width: 768px) {
        margin-top: 10px;
      }
    }

    div.checkbox {
      label {
        font-size: 16px;
        font-weight: bold;
      }
    }

    input {
      background-color: rgba(40, 40, 40, 0.85);
      margin-bottom: 16px;
      border: 1px solid #444;
      border-radius: 16px;
      color: #fff;
      font-weight: 500;
      text-align: left;
    }

    input.input-lg {
      padding: 0px 12px;
      height: 38px;

      @media (min-width: 768px) {
        height: 46px;
        padding: 4px 12px;
      }
    }

    input:focus {

    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px #eee inset;
      -webkit-text-fill-color: black !important;
    }

    input::placeholder {
      color: #fff;
    }

    span.input-group-btn {
      margin-left: 20px;
    }

    p.help-block {
      margin-bottom: 5px;

      a {
        font-size: 14px;
      }
    }

    .checkbox {
      margin-top: 0px;
      margin-bottom: 5px;
    }

    input.btn {
      background-color: @smart-blue;
      color: #fff;
      text-shadow: none;
    }

    a {
      color: #fff;
    }

    a:hover {
      color: @smart-blue;
    }

    button {
      margin: 10px 0px;
      padding: 6px 25px;
      border-radius: 12px;
      border: 0;
      color: #fff;
      font-size: 16px;
      font-weight: 700;

      @media (min-width: 768px) {
        margin: 20px 0px;
        padding: 8px 40px;
      }
    }

    div.alert {
      color: #fff;
    }

    div.alert-danger {
      background-color: #ff0000;
    }

    div.alert-info {
      color: #31708f;
    }
  }

}

.loginpanel {
  @media screen and (max-width: 768px) {
    .input-group {
      width: 100%;
      padding: 20px;
    }

    .input-group-btn {
      width: 100% !important;
      display: block;

      .btn {
        width: 100%;
        display: block;
      }
    }
  }
}

