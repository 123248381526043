/* NAVBAR */
@navbar-height: 80px;
@navbar-default-color: #fff;
@navbar-default-link-color: #fff;
@navbar-default-link-hover-color: #aaa;

nav {
  position: fixed;
  display: block;
  color: #fff;
}

.navbar-fixed-top {
  z-index: 1000;
}

.navbar-default {
  background-color: rgba(0, 0, 0, 0.7);
  border-color: #323742;

  .navbar-collapse {
    position: relative;
  }

  .navbar-nav {
    @media screen and (max-width: 768px) {
      width: 50%;
    }

    @media screen and (min-width: 768px) and (max-width: 1100px) {
      > li > a {
        padding: 30px 4px !important;
      }
    }

    @media screen and (min-width: 1101px) and (max-width: 1200px) {
      > li > a {
        padding: 30px 8px !important;
      }
    }
  }

  ul.navbar-nav > li {

    a {
      color: #fff;
      font-size: 1.1em;
      font-weight: 700;
      text-transform: uppercase;
    }

    a:hover {
      background-color: rgba(255, 255, 255, 0.8);
      color: @smart-blue;
    }
  }

  div#userinfo {
    @media screen and (max-width: 768px) {
      position: absolute;
      top: 0;
      right: 0;
    }

    float: right;
    padding: 10px 10px 5px 10px;

    p {
      line-height: 1.3em;
      margin: 0px;
      text-align: right;
      font-size: 13px;
      font-weight: bold;
    }

    button {
      float: right;
      margin-top: 5px;
      font-weight: bold;
    }
  }

}

a.navbar-brand {
  padding: 0px 10px 0px 10px;

  img {
    height: @navbar-height;
  }

}

.collapsing, .in {
  background-color: rgba(50, 55, 66, 1.0);
  background-image: none;
}

.totals_display .collapsing, .in {
  //background-color: #FFF;
}

/* END NAVBAR */


//FROM PREVIOUS MASSIVE LESS FILE:
.navbar-default .navbar-toggle {
  display: block;

  @media screen and (min-width: 768px) {
    display: block;
    float: right;
  }

  @media screen and (min-width: 992px) {
    display: none;
  }
}

.navbar-collapse.collapse {
  display: none;

  @media screen and (min-width: 768px) {
    display: none !important;
    margin-left: -10px;
    margin-right: -10px;
    border-top: 1px solid #e7e7e7 !important;
  }

  @media screen and (min-width: 992px) {
    display: block !important;
    margin-left: 200px;
    margin-right: 0;
    border: none !important;
    float: none;
  }
}

.navbar-nav > {
  @media screen and (min-width: 768px) {
    float: none;
    margin: 0;
  }

  @media screen and (min-width: 992px) {
    float: left;
  }

  li {
    @media screen and (min-width: 768px) {
      float: none;
    }

    @media screen and (min-width: 992px) {
      float: left;
    }
  }
}

.collapse.in {
  display: block !important;

  @media screen and (min-width: 768px) {
    overflow-y: auto !important;
  }
}

.container-fluid > .navbar-header, .container > .navbar-header {

  @media screen and (min-width: 768px) {
    width: 100%;
  }

  @media screen and (min-width: 992px) {
    width: auto;
    float: left;
  }
}

.navbar-header {
  @media screen and (min-width: 768px) {
    float: none;
    position: absolute;
  }
}

.navbar-nav > li > a {
  padding-top: 5px !important;
  padding-bottom: 5px !important;

  @media screen and (min-width: 768px) {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    line-height: 20px !important;
  }
}