/* FILTER BAR */

div#filters {
  padding-bottom: 15px;
  position: relative;
  margin-top: 10%;
  background-color: rgba(0, 0, 0, 0.0);

  @media screen and (max-width: 767px) {
    margin-top: 70px;
  }

  @media screen and (min-width: 768px) {
    padding-bottom: 0;
  }

  &.reports {

    margin-top: 10%;

    .dates {
      margin-top: 30px;
      margin-bottom: 0px;
    }
  }

  a.btn-blue, button.btn-blue {
    background-color: @smart-blue;
    font-weight: bold;
  }

  a.btn-yellow, button.btn-yellow {
    background-color: @new;
    font-weight: bold;
  }

  a.btn-red, button.btn-red {
    background-color: @notQualified;
    font-weight: bold;
  }

  a.btn-green, button.btn-green {
    background-color: @button-success;
    font-weight: bold;
  }

  table.datenav {
    border: 0;
    margin-bottom: 0;

    td {
      padding: 0px 4px;
      border: 0;
    }

    a.btn {
      width: 100%;
      font-weight: 700;
      font-size: 13px;
    }
  }

  h1 {
    margin-top: 10px;
    font-size: 36px;
    font-weight: 700;
    text-transform: uppercase;

    @media (min-width: 768px) {

      font-size: 48px;

    }

  }

  h3 {
    margin-top: 10px;
  }

  label {

    display: block;
    padding: 5px 0px 0px 0px;
    font-size: 16px;
    color: #fff;

    @media (min-width: 768px) {
      font-size: 18px;
    }

  }

  select {
    display: inline;
    color: @smart-blue;
    font-weight: bold;
    font-size: 13px;
  }

  input {
    color: @smart-blue;
    font-weight: bold;
    font-size: 13px;
  }

  input.startdate,
  input.enddate {
    text-align: center;
  }

  div.page-title {
    h1 {
      margin-top: 16px;
    }
  }

  .btn {
    //min-width: 90px;
    background-color: @smart-blue;
    color: #fff;

    @media screen and (max-width: 767px) {
      font-weight: 700;
    }

  }

  a.btn:hover {
    background-color: #016da3;
  }

  a.btn-print {
    background-color: @button-success;
    border-color: #ccc;
  }

  a.btn-print:hover {
    background-color: #449d44;
  }

  a.btn-warning {
    background-color: #f0ad4e;
    border-color: #ccc;
  }

  a.btn-warning:hover {
    background-color: #d58512;
  }

  a#search-patient, .no-label, #filter-patient {
    margin-top: 32px;
    //padding: 6px 2px;

    @media screen and (max-width: 767px) {
      margin-top: 12px;
      padding: 6px 10px;
    }

  }

  div.status {

    width: 100%;

    button.filterstatus {
      width: 100%;
      padding: 5px 5px 5px 10px;
      border-radius: 4px;
      color: @smart-blue;
      background-color: #fff;
      font-size: 13px;
      font-weight: 700;
      text-align: center;

      a {
        font-weight: 700;
      }

      span.caret {
        margin-left: 2px;
      }

    }

    button.showall, button.notQualified, button.unableToReach, button.patientOutOfArea,
    button.patientDeclined, button.new, button.attemptingContact,
    button.screeningVisitScheduled, button.preScreening, button.inScreening,
    button.screenFailed, button.randomized, button.sitePending {
      width: 100%;
      padding: 5px 4px 5px 6px;
      border-radius: 4px;
      color: #fff;
      font-size: 13px;
      font-weight: 700;
      text-align: center;
    }

    button.showall {
      color: @smart-blue;
      background-color: #fff;
    }

    button.notQualified {
      background-color: @notQualified;
    }

    button.unableToReach {
      background-color: @notQualified;
    }

    button.patientOutOfArea {
      background-color: @notQualified;
    }

    button.patientDeclined {
      background-color: @notQualified;
    }

    button.new {
      background-color: @new;
    }

    button.attemptingContact {
      background-color: @new;
    }

    button.screeningVisitScheduled {
      background-color: @new;
    }

    button.preScreening {
      background-color: @new;
    }

    button.inScreening {
      background-color: @new;
    }

    button.screenFailed {
      background-color: @screenFailed;
    }

    button.randomized {
      background-color: @randomized;
    }

    button.sitePending {
      background-color: @sitePending;
    }

    ul.dropdown-menu {
      padding: 0;
      margin: 0;
      width: 100%;

      li {

        a {
          padding: 3px 10px;

          color: #fff;
          font-weight: 700;
          line-height: 1.8em;
        }

        a:hover {
          background-color: #f0f0f0;
        }
      }

      li.showall {
        color: #444;
        text-align: left;
        border-bottom: 1px solid #ccc;

        a {
          color: #444;
        }

        a:hover {
        }
      }

      li.status-divider {
        padding: 5px 0px;
        text-align: center;
        font-weight: bold;
        background-color: #fff;
        color: #444;
      }

      li.notQualified {
        background-color: @notQualified;

        a:hover {
          color: @notQualified;
        }
      }

      li.unableToReach {
        background-color: @notQualified;

        a:hover {
          color: @notQualified;
        }
      }

      li.patientOutOfArea {
        background-color: @notQualified;

        a:hover {
          color: @notQualified;
        }
      }


      li.patientDeclined {
        background-color: @notQualified;

        a:hover {
          color: @notQualified;
        }
      }

      li.new {
        background-color: @new;

        a:hover {
          color: @new;
        }
      }

      li.attemptingContact {
        background-color: @new;

        a:hover {
          color: @new;
        }
      }

      li.screeningVisitScheduled {
        background-color: @new;

        a:hover {
          color: @new;
        }
      }

      li.preScreening {
        background-color: @new;

        a:hover {
          color: @new;
        }
      }

      li.inScreening {
        background-color: @inScreening;

        a:hover {
          color: @inScreening;
        }
      }

      li.screenFailed {
        background-color: @screenFailed;

        a:hover {
          color: @screenFailed;
        }
      }

      li.randomized {
        background-color: @randomized;

        a:hover {
          color: @randomized;
        }
      }

      li.sitePending {
        background-color: @sitePending;

        a:hover {
          color: @sitePending;
        }
      }
    }
  }

  div#user-errors {
    position: absolute;
    top: -140px;
    width: 100%;
    height: 140px;
    background-color: rgba(255, 0, 0, 0.5);
    overflow: hidden;
    color: #fff;

    text-align: center;

    p {
      color: #fff;
      font-size: 14px;
      font-weight: bold;
    }

    h4 {
      font-size: 24px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  div.uploader {
    text-align: center;
  }

  input.upload {
    margin: 5px auto;
    padding: 5px 8px;
    color: #fff;
    text-align: center;
  }

  label.upload {
    text-align: center;
  }

  button.upload {
    display: block;
    margin: 20px auto;
  }

  div.panel {

    h3 {
      margin: 7px 0;
      font-size: 24px;
      font-weight: bold;
      color: @smart-blue;
    }

    label {
      font-size: 15px;
      color: @smart-grey;
    }

    input[type=checkbox] {

    }

    button {

      margin: 20px auto;

    }

  }

}

/* END FILTER BAR */
