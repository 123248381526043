div#errors, div#warnings, div#success {

  display: none;

  position: fixed;
  left: 0;
  right: 0;
  top: 80px;

  background-color: rgba(255, 0, 0, 0.8);
  overflow: hidden;
  color: #fff;
  z-index: 100;

  text-align: center;

  p {
    color: #fff;
    font-size: 16px;
    font-weight: bold;
  }

  h4 {
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
  }

}

div#success {
  background-color: rgba(92, 184, 92, 0.8);
}

