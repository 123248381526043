/* SCREENERS */

.page {
  background: #fff;
  padding: 20px;
  border: 1px solid #ddd;
}

.screener h1 {
  color: gray;
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 40px;
}

.screener .section-divider {
  clear: both;
  margin-bottom: 60px;
}

.fivehundredwidth {
  width: 500px;
}

.screener .largerInput {
  font-size: 16px;
  font-weight: bold;
  padding: 5px;
}

.screener .description {

}

.screener .accordion h6 {
  font-size: 14px;
  font-weight: bold;
  border-radius: 0;
  outline: none;
  cursor: pointer;
  border: #ccc 1px solid;
  border-bottom: none;
}

.screener .accordion .toggleBody {
  padding: 10px;
  border-top: none;
  border-bottom: none;
  border-radius: 0;
}

.screener label {
  font-size: 18px;
}

.screener .campaignSelect {
  font-size: 14px;
  font-weight: bold;

}

.screener .prompt-t {
  width: 100%;
  height: 80px;
}

.gray-outline {

  border-radius: 0;
  outline: 0;
  border: 1px solid #ccc;
  padding: 10px;
}

span.blue-instructions {
  font-weight: bold;
  color: #337ab7;
  font-size: 14px;
}


.screener .inline {
  display: inline-block;
  text-align: left;
}

.screener .step {
  font-weight: bold;
  color: #337ab7;
  width: 70px;
}

.screener .step-label {
  font-weight: normal !important;
  width: 180px !important;
  font-size: 14px !important;
  color: #333;
  text-align: left;
}

.screener .question-type {
  font-weight: normal;
  width: 100px;
  text-align: center;
}

.screener .then {
  font-weight: bold;
  color: #337ab7;
  width: 60px;
  text-align: center;
}

.screener .question-action {
  font-weight: normal;
  text-align: center;
}

.screener .light-blue-shaded {
  background-color: #eef3ff;
  padding: 10px;
}

.screener .screener-question-body input,
.screener .screener-question-body select,
.screener .screener-question-body textarea {
  margin-bottom: 20px;
}

.screener .screener-question-body input {
  padding: 5px;
  font-size: 14px;
}

.screener .screener-question-body label {

}

.screener .screener-question-body .section-divider {
  clear: both;
  margin-bottom: 30px;
}

.screener .screener-question-body .answer-number-holder {
  margin-right: 15px;
}

.screener .screener-question-body .answer-actions-holder {
  padding-left: 30px;
}

.screener li.answerRow {
  border-bottom: 1px solid #a8a8a8;
}


.screener .upload-button {
  display: block !important;
  margin-bottom: 10px;
}

.screener .clear-button {
  display: block !important;
  margin-bottom: 10px;

  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  border: 1px solid transparent;
  padding: 0px 12px 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.screener audio {
  display: block !important;
}

.mp3 {
  .uploadifive-button {
    margin-top: -23px;
    margin-right: 8px;
  }
}

.twilio-form-footer {
  .btn {
    margin-left: 8px;
  }
}

.screener-question-body {
  padding: 20px;
}

.siteSearch {
  display: none;
}

.form-section {
  margin-bottom: 25px;
}

.form-footer {
  margin-bottom: 100px;
}

#templates {
  display: none;
}

.answersHolder {
  display: none;
}

.answersHolder label {
  color: #333 !important;
  font-weight: 300 !important;
}

.answers {
  margin: 12px 0;
}

span.then {
  color: gray !important;
}

.addAnswer {
  display: none;
}

.sortable {
  padding-left: 0;
  list-style: none;
}


/*.uploadifive-button{
	override the defaults from uploadify styles, i dont know where to declare them in uploadify config
	height: auto !important;
    line-height: auto !important;
    overflow: auto !important;
    position: inherit !important;
    text-align: center !important;
    width: auto !important;
}
.uploadifive-button input[type=file]{
	height: auto !important;
    line-height: auto !important;
    overflow: auto !important;
    position: inherit !important;
    text-align: center !important;
    width: auto !important;
}*/

/*.uploadifive

.uploadifive-button audio
*/
/* END SCREENERS */
