
div.reports2-landing {

  margin: 80px 0 0 0;

  h1 {
    padding: 5px 0px;
    background-color: transparent;
    font-size: 48px;
    font-weight: bold;
  }

  p {
    margin-bottom: 14px;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
  }

  form#report-form {
    select.landing {
      margin: 50px 0 0 0;
      color: #0087cb;
      font-weight: bold;
    }
  }
}

select#report_campaign {

  option.select-hr {
    border-bottom: 1px dotted black;
  }
}

div.report_nav {

  margin: 0 0 10px 0;

  @media (min-width: 768px) {
    margin: 30px 0 0 0;
  }

  a {
    font-weight: bold;
  }

  .col-xs-24 {
    padding: 0;
  }

  ul.nav-tabs, ul.nav-pills {

    li {

      a {
        background-color: #6e6f71;
      }

      a:hover {
        background-color: @smart-blue;
        color: #fff;
      }

      a.btn-success {
        background-color: #5cb85c;
      }

      a.btn-success:hover {
        background-color: @smart-blue;
      }
    }

    li.active {

      a {
        background-color: #fff;
        color: @smart-blue;
      }
    }
  }

  ul.nav-pills {

    li {
      width: 31%;

      margin: 3px 4px;

      a {

        padding: 5px 1px;
        font-size: 11px;
        font-weight: 700;

      }
    }
  }
}

.reports2 {

  padding-top: 0px;
  padding-bottom: 40px;

  background-color: #fff;

  p {
    margin-bottom: 10px;
    font-size: 13px;
    font-weight: normal;
    color: #808080;

    @media (min-width: 768px) {
      font-size: 15px;
    }

  }

  h3 {
    margin: 20px 0 20px 0px;
    color: #666;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.4em;

    @media (min-width: 768px) {
      margin: 25px 0 20px 0px;
      font-size: 24px;
    }
  }

  span.badge {
    min-width: 36px;

  }

  form#report-form {
    div.form-group {
      margin-bottom: 5px;
    }

  }

  div.progress {
    margin: 20px 0px;

    height: 40px;

    label {
      font-size: 16px;
    }

    div.progress-bar {

      min-width: 2em;

      padding: 10px 0px;

      label {
        color: #fff !important;
      }
    }

    div.progress-bar-negative {
      float: right;

      padding: 10px 0px;

      text-align: center;
    }
  }

  div.panel {

    min-height: 150px;
    text-align: center;
    border: 0;

    div.panel-heading {

      color: #fff;
      font-size: 18px;
      font-weight: bold;

    }

    div.panel-heading.smartblue {
      background-color: @smart-blue;
    }

    div.panel-heading.responses {
      background-color: #0b3f6e;
    }

    div.panel-heading.referrals {
      background-color: #00d2ff;
    }

    div.panel-heading.pending {
      background-color: #ffaa01;
    }

    div.panel-heading.enrolled {
      background-color: #19a818;
    }

    div.panel-heading.site-pending {
      background-color: #da4a63;
    }

    div.panel-body {

      @media (min-width: 768px) {
        padding: 20px 40px;
      }

      label {
        margin: 0;
        color: #666;
        font-size: 14px;
        font-weight: bold;

        @media (min-width: 768px) {
          font-size: 16px;
        }
      }

      i.responses {
        color: #0b3f6e;
      }

      i.referrals {
        color: #00d2ff;
      }

      i.pending {
        color: #ffaa01;
      }

      i.enrolled {
        color: #19a818;
      }

      i.site-pending {
        color: #da4a63;
      }

      span.label {
        margin: 14px;
        font-size: 18px;
        color: #fff;
        display: inline-block;
        vertical-align: top;

        @media (min-width: 768px) {
          font-size: 20px;
        }
      }

      span.responses {
        background-color: #0b3f6e;
      }

      span.referrals {
        background-color: #00d2ff;
      }

      span.pending {
        background-color: #ffaa01;
      }

      span.enrolled {
        background-color: #19a818;
      }

      span.site-pending {
        background-color: #da4a63;
      }

    }

    div.panel-body.responses {
      background-color: rgba(11, 63, 110, 0.05);
    }

    div.panel-body.referrals {
      background-color: rgba(0, 210, 255, 0.05);
    }

    div.panel-body.pending {
      background-color: rgba(255, 170, 1, 0.05);
    }

    div.panel-body.enrolled {
      background-color: rgba(25, 168, 24, 0.05);
    }

    // div.panel-body.enrolled {
    //   background-color: rgba(218, 74, 99, 0.05);
    // }

  }

  div.well {
    margin: 10px 0;
    padding-bottom: 10px;

    background-color: #fff;

    p {
      margin: 0px;
      margin-top: -9px;
      font-weight: 700;
      font-size: 13px;
      color: #999;

      i.success {
        color: #f0ad4e;
      }

      i.danger {
        color: #a94442;
      }
    }

    h2 {
      margin: 0px;
      color: @smart-blue;
      font-size: 20px;
      font-weight: 700;

      span.label {
        padding: .6em .8em;
        font-size: 18px;
        margin-left: 10px;
      }

      span.label-warning {
        background-color: #449d44;
      }

    }

    i.fa {
      color: @smart-blue;
    }

  }

  table#referral_status_summary,
  table#referral_status_source_summary,
  table#referral_status_detail {
    font-size: 13px;
    color: @smart-grey;

    th {
      padding: 4px 12px 2px 12px;
      color: @smart-grey;
      text-transform: uppercase;
      vertical-align: middle;

      a.underline {
        text-decoration: underline;
      }

      .fa-sort-asc {
        vertical-align: -20%;
      }

      .fa-sort-desc {
        vertical-align: 10%;
      }
    }

    td {
      vertical-align: middle;

      h3 {
        margin: 5px 2px;
      }
    }

    td.pending, td.site-pending, td.excluded, td.screened, td.failed, td.smart,
    th.pending, th.site-pending, th.excluded, th.screened, th.failed, th.smart {
      background-color: rgba(255, 160, 10, 0.1);
      font-size: 14px;
      color: @new;
      text-transform: uppercase;
      text-align: center;
      vertical-align: middle;
    }

    td.site-pending, th.site-pending {
        background-color: rgba(100,100,100,.1);
        color: #666;
    }

    td.excluded, th.excluded {
      background-color: rgba(200, 0, 0, 0.1);
      color: @notQualified;
    }

    td.screened, th.screened {
      background-color: rgba(30, 206, 5, 0.1);
      color: @randomized;
    }

    td.failed, th.failed {
      background-color: rgba(103, 108, 76, 0.2);
      color: @screenFailed;
    }

    td.smart, th.smart {
      background-color: rgba(0, 135, 203, 0.1);
      border-left: 2px solid #ccc;
    }

    td.total, th.total {
      border-left: 2px solid #ccc;
      border-right: 2px solid #ccc;
    }

    td.roll {
      cursor: pointer;
    }

    span {
      padding: 4px 10px;
      font-size: 14px;
    }

    span.pending {
      background-color: @new;
    }

    span.excluded {
      background-color: @notQualified;
    }

    span.screened {
      background-color: @randomized;
    }

    span.smart {
      background-color: @smart-blue;
    }

    span.total {
      margin-top: -12px;
      padding: 7px 14px;
      font-size: 20px;
      background-color: @smart-blue;
    }

    i.danger {
      color: @notQualified;
    }

    i.warning {
      color: @new;
    }

    i.active {
      color: @randomized;
    }

  }

  table#referral_status_source_summary {
    margin-top: 15px;
  }

  div.input-group {

    margin-bottom: 5px;

    .btn {


    }

    input {

    }
  }

}

div#status-guide {

  background-color: rgba(50, 55, 66, 0.6);

  .modal-body {
    padding: 0px 15px;
  }

  h4 {
    font-weight: 700;
  }


  .row {
    margin: 0px -15px;
    padding: 10px 0px;

  }

  .row.pending {
    background-color: rgba(255, 160, 10, 0.1);
    color: @new;

    h4 {
      margin: 0px;
      color: inherit;
    }
  }

  .row.excluded {
    background-color: rgba(200, 0, 0, 0.1);
    color: @notQualified;

    h4 {
      margin: 0px;
      color: inherit;
    }
  }

  .row.screened {
    background-color: rgba(30, 206, 5, 0.1);
    color: @randomized;

    h4 {
      margin: 0px;
      color: inherit;
    }
  }

  .row.failed {
    background-color: rgba(103, 108, 76, 0.1);
    color: @screenFailed;

    h4 {
      margin: 0px;
      color: inherit;
    }
  }

  h4.modal-title {
    color: #666;
  }
}

.datastudio-embed {
  iframe {
    width: 100% !important;
    min-height: 1000px;
  }
}
