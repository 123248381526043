/* HEADER/FOOTER BAR */

div#header-bar, div#footer-bar
{
  margin-top: 0px;
  background-color: rgba(0,0,0,0.7);
  color: white;
  display: none;

  @media screen and (min-width: 768px) {
	  display: block;
  }

  .row {
    padding: 5px 0px 2px 0px;

    p {
      margin-bottom: 0px;
      padding: 5px 0px;
      font-size: 10px;
      font-weight: bold;

      @media(min-width: 768px) {
        font-size: 13px;
      }
    }

    a {
      font-size: 13px;
      color: #fff;
    }

    a:hover {
      color: @smart-blue;
      text-decoration: none;
    }

    a.underline {
      text-decoration: underline;
    }

    button {
      padding: inherit 10px;
      font-weight: bold;
    }

    button.saveall {
      margin-top: 4px;
      display: none;
    }
  }

  div.updatenotice {
    text-align: center;

    p {
      font-size: 13px;
    }

    a.btn {
      position: relative;
      top: -1px;
      left: 5px;
      font-weight: bold;
    }

    a.btn:hover {
      color: #fff;
    }

  }

}

div#footer-bar {

  min-height: 36px;
  padding: 0px;

  button#activateall {
    margin-top: 4px;
  }
}

.loading-spinner-gray{
	display: block;
	width: 32px;
	height: 32px;
	background: url('/images/loading.gif') center no-repeat;
	background-size: contain;
}

/* END HEADER/FOOTER BAR */
