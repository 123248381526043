/* FOOTER */
div#footer {
  z-index: 100;
  position: fixed;
  bottom: 0px;
  width: 100%;

  margin: 0px;
  padding: 4px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  text-align: center;

  p {
    font-size: 9px;
    font-weight: 700;
    margin-bottom: 0px;

    @media (min-width: 768px) {
      font-size: 11px;
    }
  }

  a {
    color: #fff;
  }

  a:hover {
    color: #57e1ff;
    text-decoration: none;
  }
}
