div#campaign-data,
div#study-data,
div#patient-data,
div#site-data,
div#user-data {
  div#loading-spinner {
    padding: 100px 0px;
    text-align: center;
    color: @smart-blue;
  }
}

div#transfer-data {
  padding-bottom: 38px;
}

/* NO ELEMENTS FOUND */
div#no-campaigns-found,
div#no-studies-found,
div#no-patients-found,
div#no-sites-found,
div#no-users-found {

  h1 {
    padding: 40px 0px;
    background-color: rgba(255, 255, 255, 0.7);
    font-size: 36px;
    font-weight: 700;
    color: #777;
  }
}

div#updateUser,
div#updateTransfer,
div#confirmUser {
  background-color: rgba(0, 0, 0, 0.3);

  margin-bottom: 60px;
  padding-bottom: 60px;

  h1 {
    text-transform: uppercase;
    font-size: 32px;
    font-weight: bold;
  }

  h3 {
    margin-top: 5px;

    span {
      padding: 12px;
    }
  }

  select {
    font-weight: bold;
    font-size: 16px;
  }

  .form-group {
    margin-bottom: 5px;
  }

  label {
    margin-top: 10px;
    font-size: 16px;
    color: #fff;

    @media screen and (min-width: 768px) {
      margin-top: 20px;
    }
  }

  input {
    background-color: rgba(220, 220, 220, 0.90);
    border: 1px solid #fff;
    color: #222;
    text-shadow: 1px 1px 1px #c0c0c0;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
  }

  input.input-lg {
    padding: 0px 12px;
    height: 38px;

    @media screen and (min-width: 768px) {
      height: 46px;
      padding: 4px 12px;
    }
  }

  div.campaign-list {
    display: none;
  }

  ul.campaigns {
    margin: 0px;
    padding: 5px 5px 0px 10px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 166px;
    overflow: scroll;

    li {

      border-bottom: 1px dotted #ccc;

      input[type=checkbox] {
        margin: 0px;
        position: relative;
      }

      div.checkbox {
        margin-top: 8px;
        margin-bottom: 8px;
      }

      label {
        color: @smart-blue;
        font-size: 14px;
        font-weight: bold;
      }
    }

    li:last-child {
      border-bottom: none;
    }

    div.checkbox {
      label {
        margin-top: 0px;
        color: #222;
      }
    }

  }

  p.help-block {
    margin-bottom: 5px;
  }

  .checkbox {
    margin-top: 0px;
    margin-bottom: 5px;
  }

  input.btn {
    background-color: @smart-blue;
    color: #fff;
    text-shadow: none;
  }

  a {
    color: #fff;
  }

  a:hover {
    color: @smart-blue;
  }

  a.btn {
    display: block;
    width: 30%;
    margin: 0px auto 60px auto;
    text-align: center;
    background-color: @smart-blue;
  }

  a.btn:hover {
    background-color: #fff;
    color: @smart-blue;
  }

  div.alert {
    color: #fff;
  }

  div.alert-danger {
    background-color: #ff0000;
  }

  div.well.logins {

    h1 {
      margin-bottom: 10px;
    }

    p {
      margin-bottom: .5em;
      font-weight: bold;
    }

  }

}

div#confirmUser {
  a.btn {
    display: inline-block;
    width: 33%;
    margin: 2em;
    background-color: #f0ad4e;
  }

  a.btn:hover {
    background-color: #ec971f;
    border-color: #d58512;
    color: #fff;
  }

  button {
    width: 33%;
    margin: 2em;
  }
}

div#updateTransfer {
  a.btn {
    width: 100%;

    font-weight: bold;
  }

  a.btn-warning {
    background-color: red;
    border-color: #fff;
  }

}

form.create {
  margin-top: 25px;
  margin-bottom: 25px;

  label {
    text-align: left;
  }
}

