div#new-user-panel,
div#search-user-panel,
div#new-patient-panel,
div#new-patient-panel-select-study,
div#search-patient-panel,
div#new-study-panel,
div#search-study-panel,
div#search-campaign-panel,
div#new-campaign-panel {
  display: none;

  padding-bottom: 10px;

  background-color: rgba(0, 0, 0, 0.3);

  li.collapsing, .in {
    background-color: #fff;
    background-image: none;
    min-height: 100px;
  }

  h1, h2, h3, h4 {
    margin-top: 10px;
  }

  h3 {
    font-weight: bold;
  }

  label {
    color: #fff;
    font-size: 18px;
  }

  input {
    color: @smart-blue;
    font-size: 13px;
    font-weight: 700;
  }

  a.btn {
    min-width: 90px;
    background-color: @smart-blue;
    border-color: #ccc;
    color: #fff;
  }

  a.btn:hover {
    background-color: #016da3;
  }

  .no-label {
    margin-top: 28px;
    padding: 6px 12px;

    @media screen and (max-width: 767px) {
      margin-top: 12px;
      font-weight: 700;
    }
  }

  select {
    display: inline;
    color: @smart-blue;
    font-weight: bold;
    font-size: 13px;
  }

  select#role {
    font-weight: bold;
  }

  div#campaigns-panel {
    display: none;
  }

  div.bootstrap-switch {
    height: 2.3em;
    font-weight: bold;
    font-size: 13px;
    color: @smart-blue;

    span {
      padding: 4px 14px;
    }

    span.bootstrap-switch-handle-off {
      color: @smart-blue;
    }
  }

  a#add-patient {
    background-color: @button-success;
  }
}

div#search-panel {
  background-color: rgba(0, 0, 0, 0.70);
  padding-left: 10px;
  padding-right: 10px;

  a#search {
    margin-top: 1.8em;
  }

}

div#new-campaign-panel {

  //makes scrollbar visible on overflow for Safari
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 9px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }

  textarea {
    height: 165px;
    color: @smart-blue;
    font-size: 14px;
    font-weight: bold;
  }

  ul.new_campaign_studies {
    margin: 0px;
    padding: 5px 5px 0px 10px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 166px;
    overflow: scroll;

    li {

      border-bottom: 1px dotted #ccc;

      input[type=checkbox] {
        margin: 0px;
        position: relative;
      }

      div.checkbox {
        margin-top: 8px;
        margin-bottom: 8px;
      }

      label {
        color: @smart-blue;
        font-size: 14px;
        font-weight: bold;
      }
    }

    li:last-child {
      border-bottom: none;
    }

  }
}

div#new-user-panel {

  ul.campaigns {
    margin: 0px;
    padding: 5px 5px 0px 10px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 166px;
    overflow: scroll;

    li {

      border-bottom: 1px dotted #ccc;

      input[type=checkbox] {
        margin: 0px;
        position: relative;
      }

      div.checkbox {
        margin-top: 8px;
        margin-bottom: 8px;
      }

      label {
        color: @smart-blue;
        font-size: 14px;
        font-weight: bold;
      }
    }

    li:last-child {
      border-bottom: none;
    }

  }


}
