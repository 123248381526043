html {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  font-family: 'Lato', sans-serif;
}

body {
  padding-top: 80px !important;
  min-height: 100%;
  height: auto;
  background-color: rgba(0, 0, 0, 0.2);
}

pre {
  font-size: 11px;
  width: 50%;
}

h1, h2, h3, h4, h5, h6 {
  color: white;
  font-family: 'Lato', sans-serif;
}

h1 {
  font-size: 48px;
  font-weight: 300;
  text-align: center;
  line-height: 1.2em;
  margin: 0;

  @media screen and (min-width: 768px) {
    font-size: 60px;
  }
}

p {
  font-size: 12px;
  margin-bottom: 25px;
}

textarea {
  font-size: 12px;
}

.input-xs, select.input-xs {
  height: 22px;
  width: 90%;
  line-height: 22px;
  font-family: 'FontAwesome';
  font-size: 13px;
  color: @smart-blue;
}

option {
  font-family: 'FontAwesome';
  color: @smart-blue;
}

option.blue {
  color: blue;
}
