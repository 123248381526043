/** Print Stylesheet **/

@media print {


  /**
   * Overrides
   */
  /** Grid **/
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4,
  .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8,
  .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12,
  .col-sm-13, .col-sm-14, .col-sm-15, .col-sm-16,
  .col-sm-17, .col-sm-18, .col-sm-19, .col-sm-20,
  .col-sm-21, .col-sm-22, .col-sm-23, .col-sm-24 {
    float: left !important;
  }

  .col-sm-1 {
    width: 4.16666667% !important;
  }

  .col-sm-4 {
    width: 16.66666667% !important;
  }

  .col-sm-3 {
    width: 12.5% !important;
  }

  .col-sm-2 {
    width: 8.33333333% !important;
  }

  .visible-xs {
    display: block !important;
  }

  .patient-data-row > .col-md-24 > .row {
    > div[class^="col-"] {
      width: 11% !important;
      float: left !important;
      margin: 0 !important;
      padding: 0 !important;
    }

    label, p, input, button {
      text-align: left !important;
    }

    .col-md-2 {
      .col-xs-12 {
        width: 100% !important;
      }
    }

    .form-group {
      margin: 0 !important;
    }

    div.col-md-6 {
      width: 34.5% !important;

      .form-group {
        width: 31.33333% !important;
        float: left !important;
      }

      .row {
        width: 67.6666666% !important;
        float: left !important;

        .col-md-12 {
          width: 50% !important;
          float: left !important;
        }

        .form-group {
          width: 100% !important;
        }
      }

      &.print-col-2 {
        width: 22% !important;

        .form-group, .row {
          width: 50% !important;

          .col-md-12 {
            margin: 0 !important;
            padding: 0 !important;

            .form-group {
              width: 100% !important;
            }
          }
        }
      }

      &.print-col-1 {
        width: 15% !important;

        .form-group {
          width: 100% !important;
        }
      }
    }
  }


  /** End Grid **/
  html {
    padding: 25px !important;
  }

  body {
    padding: 0 !important;
    -webkit-print-color-adjust: exact;
  }

  div, p, input, textarea {
    font-size: 11px !important;
  }

  label {
    font-size: 10px !important;
    font-weight: bold !important;
  }

  input, select, textarea {
    border: none !important;
    padding: 0 !important;
    background: none !important;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
  }

  textarea {
    min-height: 35px !important;
  }

  .input-group-addon {
    display: none !important;
  }

  #filters {
    margin: 0 !important;

    h1 {
      text-align: left;
      margin-bottom: 25px;
    }
  }

  #patient-filters {
    display: none;
  }

  #create-patient, #search-patient, #import-patient, .delete-patient, #filter-patient {
    display: none;
  }

  #header-bar {
    display: none !important;
  }

  .patient-data-bar {
    border-bottom: 1px solid #999 !important;
    padding-left: 15px !important;
    padding-right: 15px !important;

    &:nth-child(even) {
      background: #f2f2f2 !important;
    }

    .toggle-edit {
      display: none;
    }

    .dropdown-toggle {
      font-size: 11px !important;
      background-color: none !important;
      color: #313131;
      text-align: left;

      .caret {
        display: none;
      }
    }
  }

  .edit-panel {
    display: block !important;
  }

  .status-col {
    width: 100px !important;
    font-size: 12px !important;
  }

  .detail-col, .active-col, .recordings-col, .recordings, .notes-col, .delete-patient-col {
    display: none !important;
  }

  .email-col {
    width: 150px !important;
  }

  #search-patient-panel {
    display: none !important;
  }

  #footer {
    position: relative !important;

    p {
      text-align: left !important;
    }
  }

  #img-select {
    display: none;
  }


}



