/* DATA BAR */

#patient-filters {
  @media screen and (max-width: 767px) {
    display: none;
  }
}

div.campaign-data-bar,
div.study-data-bar,
div.site-data-bar,
div.patient-data-bar,
div.user-data-bar,
div.transfer-data-bar {
  position: relative;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.80);
  color: @smart-blue;
  border-bottom: 1px solid @smart-blue;
  min-height: 46px;

  @media screen and (max-width: 767px) {
    padding-bottom: 10px;
  }

  h1 {
    color: @smart-blue;
  }

  div.patient-validation-error {

    display: none;
    position: relative;
    top: -9px;
    margin-bottom: 2px;
    background-color: rgba(255, 0, 0, 0.9);
    color: #fff;
    text-align: center;

    div.error-messages {

      p.message {
        padding: 6px 8px;
        text-align: center;
        font-size: 14px;
      }

    }

  }

  p.pii-warning {
    color: rgba(255, 0, 0, 0.9);
  }

  .savewarning {
    background-color: rgba(0, 0, 0, 0.3);
    background-color: rgba(255, 255, 0, 0.2);

  }

  .savesuccess {
    background-color: rgba(68, 157, 68, 0.3);
  }

  div.not-viewed {
    background-color: rgba(92,184,92, .3);
  }

  .row:first-child {
    padding: 9px 0px 0px 0px;

    @media screen and (max-width: 767px) {
      padding: 5px 0px 0px 0px;
    }

    p {
      padding-top: 7px;
    }
  }

  .row {
    padding: 2px 0px 0px 0px;

    button {
      padding: 5px;
    }

    div[class^="col-"] {
      @media screen and (max-width: 767px) {
        padding-top: 2px;
        padding-bottom: 3px;
      }
    }

    button.notes {
      width: 24%;
      background-color: #fff;
      border-color: #ccc;
      float: left;

      @media screen and (min-width: 768px) {
        width: 90%;
      }
    }

    button.toggle-edit {
      padding: 4px 9px;
      border: 1px solid #ccc;
      background-color: #fff;
      font-weight: 900;
      font-size: 14px;
      color: @smart-blue;

      @media screen and (max-width: 767px) {
        width: 100%;
        background-color: @smart-blue;
        color: #fff;
      }
    }

    button.active-indicator, button.edit-indicator {
      width: 34px;
      padding: 4px 2px 5px 2px !important;
      font-size: 13px;
      font-weight: bold;
    }

    button.active-indicator {
      width: 100%;
    }

    .btn[disabled] {
      color: #ccc;
    }

    h3 {
      margin: 0px;
      color: #666;
    }

    p {
      font-size: 13px;
      font-weight: 700;
      line-height: 18px;
      margin-bottom: 0px;
      padding: 0px;

      @media screen and (max-width: 767px) {
        font-size: 14px;
      }

      @media screen and (min-width: 768px) {
        text-align: center;
      }
    }

    span.phone-label {
      font-size: 14px;
    }

    p.origin, p.study, p.site, p.best {
      margin: 2px 0px 8px 0px;
      text-align: left;
      font-size: 14px;
    }

    a.btn {
      @media screen and (max-width: 767px) {
        background-color: #eee;
        font-weight: 700;
        color: @smart-blue;
      }
    }

    div.recordings {

      text-align: center;

      @media screen and (max-width: 767px) {

        padding: 20px 10px 10px 10px;

      }

      @media screen and (min-width: 768px) {
        padding-left: 0px;
        padding-right: 0px;
      }

      button.audio {
        display: inline-block;
        background-color: #fff;
        border-color: #ccc;
        margin-right: 1.33%;
        width: 24%;
        float: left;

        @media screen and (min-width: 768px) {
          width: 40%;
          margin-right: 4%;
        }
      }

      button.audio:hover {
        background-color: #ccc;
      }
    }

    label {
      @media screen and (max-width: 767px) {
        margin-bottom: 1px;
      }
    }

    div.form-group {
      margin-bottom: 10px;
    }

    div.has-error > textarea, div.has-error > input {
      background-color: rgba(255, 0, 0, 0.1);
    }

    input {
      padding: 6px 6px 4px 6px;
      color: @smart-blue;
    }

    input[type=text].input-sm {
      font-size: 13px;

      @media screen and (max-width: 767px) {
        font-size: 14px;
      }
    }

    input[type=checkbox].input-sm {
      padding: 3px 5px;
    }

    div.bootstrap-switch {
      height: 2.3em;
      font-weight: bold;
      font-size: 13px;
      color: @smart-blue;

      span {
        padding: 4px 8px;
      }

      span.bootstrap-switch-handle-off {
        color: @smart-blue;
      }
    }

    input.input-sm {
      font-size: 13px;
      font-weight: 700;
    }

    select {
      color: @smart-blue;
      font-size: 13px;
      font-weight: 700;
    }

    div.status {

      min-width: 100%;

      @media screen and (max-width: 767px) {
        margin-bottom: 10px;
      }

      button.statusbutton {
        width: 100%;
        padding: 4px 1px;
        color: #fff;
        font-weight: 700;

        @media screen and (max-width: 767px) {
          padding: 6px 1px;
          font-size: 14px;
        }

        a {
          font-weight: 700;
        }

        span.caret {
          margin-left: 2px;
        }

      }

      button.notQualified {
        background-color: @notQualified;
      }

      button.unableToReach {
        background-color: @notQualified;
      }

      button.patientOutOfArea {
        background-color: @notQualified;
      }

      button.patientDeclined {
        background-color: @notQualified;
      }

      button.new {
        background-color: @new;
      }

      button.attemptingContact {
        background-color: @new;
      }

      button.screeningVisitScheduled {
        background-color: @new;
      }

      button.preScreening {
        background-color: @new;
      }

      button.inScreening {
        background-color: @inScreening;
      }

      button.screenFailed {
        background-color: @screenFailed;
      }

      button.randomized {
        background-color: @randomized;
      }

      button.sitePending {
        background-color: @sitePending;
      }

      ul {
        padding: 0;
        margin: 0;
        width: 100%;

        li {

          a {
            padding: 3px 12px;
            color: #fff;
            font-weight: 700;
            line-height: 1.8em;
          }
        }
      }

      li.status-divider {
        padding: 5px 0px;
        text-align: center;
        font-weight: bold;
        background-color: #fff;
        color: #444;
      }

      li.notQualified {
        background-color: @notQualified;

        a:hover {
          color: @notQualified;
        }
      }

      li.unableToReach {
        background-color: @notQualified;

        a:hover {
          color: @notQualified;
        }
      }

      li.patientOutOfArea {
        background-color: @notQualified;

        a:hover {
          color: @notQualified;
        }
      }

      li.patientDeclined {
        background-color: @notQualified;

        a:hover {
          color: @notQualified;
        }
      }

      li.new {
        background-color: @new;

        a:hover {
          color: @new;
        }
      }

      li.attemptingContact {
        background-color: @new;

        a:hover {
          color: @new;
        }
      }

      li.screeningVisitScheduled {
        background-color: @new;

        a:hover {
          color: @new;
        }
      }

      li.preScreening {
        background-color: @new;

        a:hover {
          color: @new;
        }
      }

      li.inScreening {
        background-color: @inScreening;

        a:hover {
          color: @inScreening;
        }
      }

      li.screenFailed {
        background-color: @screenFailed;

        a:hover {
          color: @screenFailed;
        }
      }

      li.randomized {
        background-color: @randomized;

        a:hover {
          color: @randomized;
        }
      }

      li.sitePending {
        background-color: @sitePending;
        a:hover {
          color: @sitePending;
        }
      }
    }

    .popover {
      width: 320px;
      min-height: 80px;
      background-color: #fff;
    }

    .popover-title {
      color: @smart-blue;
      font-size: 15px;
      text-align: center;

      @media screen and (max-width: 767px) {
        font-weight: 700;
      }
    }

    .popover-content {
      padding: 9px;
      background-color: #fff;
      color: @smart-blue;
      font-size: 14px;
      text-align: center;
    }

    button.saveuser,
    button.savinguser,
    button.savesite,
    button.savestudy,
    button.savecampaign {
      display: none;
      width: 60px;
      margin: 0px auto;
      border: 1px solid #ccc;
      font-size: 13px;
      font-weight: bold;
      text-align: center;
    }

    button.saveuser, button.savinguser {
      @media screen and (max-width: 767px) {
        padding: 5px 9px;
        width: 100%;
        font-size: 14px;
      }
    }

    a.btn-patient-action {
      color: #fff;
      background-color: @smart-blue;
    }

  }

  div.edit-panel {
    position: relative;
    display: none;

    label.patient-id {
      width: 100%;
      text-align: center;
    }

    p.patient-id {
      padding: 4px 0px;
      text-align: center;
    }

    div.dates {
      padding: 0px;
    }

    input {
      padding: 6px 6px 4px 6px;
    }

    span.input-group-addon {
      padding: 0px 8px;
    }

    a.input-group-addon {
      padding: 0px 5px;
      /*background-color: @alert-danger-bg;*/
      font-size: 12px;
      font-weight: 700;
    }

    a.input-group-addon:hover {
      background-color: #eee;
      padding: 0px 5px;
      text-decoration: none;
      /*color: @alert-danger-text;*/
    }

    .white {
      background-color: #fff;
      color: @smart-blue;
    }

    .white:hover {
      background-color: #ccc;
      color: #333;
    }

    textarea {
      width: 100%;
      min-height: 80px;
      color: @smart-blue;
      font-size: 13px;
      font-weight: bold;
    }

    a.delete-patient {
      width: 100%;
      margin-top: 0px;
      padding: 4px 6px;
      font-weight: bold;
      color: #fff;
      background-color: #f0ad4e;

      @media screen and (max-width: 767px) {
        margin-top: 5px;
        padding: 5px 9px;
      }
    }

    a.confirm-delete-patient {
      display: none;
      width: 100%;
      margin-top: 0px;
      padding: 4px 6px;
      font-weight: bold;
      color: #fff;
      background-color: #d9534f;

      @media screen and (max-width: 767px) {
        margin-top: 5px;
        padding: 5px 9px;
      }

    }

    .bootstrap-select {

      .dropdown-toggle {
        color: @smart-blue;
        font-weight: bold;

      }

      .dropdown-menu {
        li {
          a {
            color: @smart-blue;
            font-weight: bold;
          }
        }
      }

      span.bs-caret {
        font-weight: bold;
      }

    }

  }
}

div.campaign-data-bar:hover,
div.study-data-bar:hover,
div.site-data-bar:hover,
div.patient-data-bar:hover,
div.user-data-bar:hover {
  background-color: rgba(255, 255, 255, 1.0);
}

//SITE DATA BAR
div.site-data-bar {
  //makes scrollbar visible on overflow for Safari
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 9px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }

  .row {
    padding-top: 0px;

    p {
      padding: 2px 0 0 0;
      text-align: left;
    }

  }

  h4 {
    margin-top: 5px;
    margin-left: -40px;
    font-size: 18px;
    font-weight: 700;
    color: @smart-blue;
  }

  label {
    width: 100%;
    margin-bottom: 2px;
  }

  label.label-centered {
    text-align: center;
    border-bottom: 1px solid #ccc;
  }

  .list-inline {
    position: relative;
    top: -7px;
    margin: 0px;
  }

  .list-inline > li {
    min-width: 13%;
    padding-left: 0px;
    padding-right: 6px;
    font-size: 13px;
    font-weight: 700;
    text-align: center;
  }

  span.badge {
    position: relative;
    width: 100%;
    top: -1px;
    margin-left: 2px;
    font-weight: bold;
    font-size: 13px;
  }

  span.pending {
    background-color: @new;
  }

  span.screened {
    background-color: @randomized;
  }

  span.excluded {
    background-color: @notQualified;
  }

  div.input-group {
    margin: 0px !important;
    font-size: 14px !important;
  }

  span.input-group-addon {
    padding: 0px 8px;
  }

  label.form-control {
    padding: 4px 6px;
    font-size: 13px;
    color: @smart-blue;
  }

  input[type="radio"] {
    padding: 0px;
    line-height: 1.4;
  }

  div.notify {
    input[type=checkbox] {
      display: inline;
    }

    label {
      display: inline;
      font-size: 12px;
    }
  }

  a.sitenote {
    padding: 5px 8px;
    background-color: #fff;
    border-color: #ccc;
  }

  .btn[disabled] {
    color: #ccc;
  }

  div.gethelp {

    border-top: 1px dotted #ccc;

    a.btn {
      font-weight: bold;
    }

  }

  div.notes {
    position: relative;
    left: 12px;
    margin: 20px 0px;
    padding: 0px 40px 10px 28px;
    background-color: rgba(200, 200, 200, 0.7);
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;

    h4 {
      margin: 0px 0px 6px 0px;
      text-align: left;
    }

    label {
      text-align: left;
    }

    ul#report-notes {
      height: 320px;
      overflow: scroll;
      border: 1px solid #ccc;
      border-radius: 4px;
      background-color: #fff;
      padding: 10px;

      li {
        border-bottom: 1px dotted #ccc;
      }
    }

    a.delete-site {
      display: block;
      margin: 5px auto;
      width: 50%;
      font-weight: bold;

    }

    a.confirm-delete-site {
      display: none;
      margin: 5px auto;
      width: 50%;
      font-weight: bold;

    }
  }

  .edit-panel {
    h4 {
      color: #F39225;
    }

    label {
      color: #4DBF40;
    }
  }

  i.green {
    color: @randomized;
  }

  i.yellow {
    color: @new;
  }

  i.red {
    color: @notQualified;
  }

  i.grey {
    color: @smart-grey;
  }

}

div#scModal {
  background-color: rgba(0, 0, 0, 0.7);

  h4 {
    color: @smart-blue;
  }

  p {
    font-size: 16px;
  }

}

//CAMPAIGN-DATA-BAR
div.campaign-data-bar {
  //makes scrollbar visible on overflow for Safari
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 9px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }

  .row {
    p {
      margin-top: -2px;
      text-align: left;
    }

    div.no_end_date {
      text-align: center;

      input[type="checkbox"] {
        display: inline-block;
        margin: 0px auto;
      }
    }

  }

  textarea {
    height: 165px;
  }

  ul.studies {
    margin: 0px;
    padding: 5px 10px 5px 30px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 166px;
    overflow: scroll;

    li {

      border-bottom: 1px dotted #ccc;

      input[type=checkbox] {

      }

      div.checkbox {
        margin-top: 8px;
        margin-bottom: 8px;
      }

      label {
        font-weight: bold;
      }
    }

    li:last-child {
      border-bottom: none;
    }

  }

  button {
    font-weight: bold;
  }

}

//USER-DATA-BAR
div.user-data-bar,
div.transfer-data-bar {
  text-align: left;

  .row {

    padding: 10px 0px !important;

    p {
      padding-top: 6px;
      text-align: left;
    }

    a.activate, a.edituser {
      width: 34px;
      padding: 4px 2px 5px 2px !important;
      font-size: 13px;
      font-weight: bold;
    }

  }
}

div.study-data-bar {
  .row {

    p {
      padding-top: 6px;
      text-align: left;
    }
  }

  .row:first-child {
    p {
      padding-top: 4px;
    }
  }


  span.badge {
    padding: 9px 0px;
    width: 100%;
    background-color: @smart-blue;
  }

}

/* END DATA BAR */
