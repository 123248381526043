/* DASHBOARD */

div#dashboard  {
  position: relative;
  top: 10%;
  padding-bottom: 50px;
  margin-bottom: 20px;

  h1 {
    padding: 10px;
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 50px;
    color: #fff;
  }

  a:hover {
    text-decoration: none;
  }

  div.logo {
    margin-bottom: 11%;
    background-color: rgba(255, 255, 255, 0.00);
    text-align: center;

    img {
      max-height: 125px;
    }

  }

  div.panel {
    margin: 20px 18px;
    border-radius: 18px;
    background-color: rgba(240, 240, 240, 0.85);
    text-align: center;
    cursor: pointer;

    h2.panel-title {
      padding: 6px 0;
      background: transparent;
      font-size: 18px;
      font-weight: 700;
      color: #0b3f6e;
      text-transform: uppercase;
    }

    div.panel-heading {
      border-radius: 18px 18px 0 0;

    }

    div.panel-body {
      padding: 32px 20px;
      color: #666;
      text-shadow: 2px 2px 2px #c0c0c0;

      h2 {
        margin-bottom: 20px;
        font-size: 18px;
        color: #0b3f6e;
      }

      p {
        font-size: 16px;
      }
    }

    .fa-4x {
      font-size: 4.6em;
      color: #0b3f6e;
    }
  }

  div.panel:hover {
    background-color: #fff;
    -webkit-box-shadow: 3px 3px 3px 0px rgba(170, 170, 170, .75);
    -moz-box-shadow: 3px 3px 3px 0px rgba(170, 170, 170, .75);
    box-shadow: 3px 3px 3px 0px rgba(170, 170, 170, .75);

    text-decoration: none;
  }

  div.panel-heading {
    background-color: rgba(250, 250, 250, 0.9);
  }

  div.modal {
    background-color: transparent;
  }

  div.modal-body {

    text-align: center;

    p {
      font-size: 18px;
      color: @smart-blue;
    }

    h2 {
      font-size: 28px;
      color: #333;
      font-weight: 400;
    }

    h2.green {
      font-size: 48px;
      color: #5cb85c;
      font-weight: 700;
    }
  }

}
