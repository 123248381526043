/* ASSISTANCE PAGE */

div#contact {
  position: relative;
  margin: 20px 100px;
  border: 1px solid rgba(255, 255, 255, 0.6);
  border-radius: 18px;
  padding: 15px 10px;
  background-color: rgba(38, 144, 205, 0.70);

  h1 {
    margin-bottom: 30px;
    text-transform: uppercase;
    font-size: 22px;

    @media screen and (min-width: 768px) {
      margin-bottom: 18px;
      font-size: 48px;
    }
  }


  i {
    margin-right: 10px;
  }

  div#contact-info, div#contact-form {
    background-color: #fff;
    border-radius: 12px;
    width: 90%;
    min-height: 505px;

    margin: 10px 10px 10px 10px;

    p, a {
      color: #757575;
      font-size: 18px;

      @media screen and (min-width: 768px) {
        font-size: 16px;
      }
    }

    ul {
      padding: 25px 10px 25px 25px;

      @media screen and (min-width: 768px) {
        padding: 10px 10px 25px 20px;
      }

      li {
        margin-bottom: 4em;
      }

      li:last-child {
        margin-bottom: 0;
      }
    }

    i {
      color: #57e1ff;
      font-size: 1.4em;
    }

    i.large {
      font-size: 2.6em;
      margin-bottom: 40px;
      float: left;
    }

  }

  div#contact-form {
    position: relative;
    padding: 15px;
    text-align: left;

    h1 {
      margin-bottom: 10px;
      font-size: 24px;
      color: #757575;
      text-align: left;
    }

    input {
      margin-bottom: 10px;
      border-radius: 0;
      font-family: 'Lato', sans-serif;
      font-weight: 300;
    }

    textarea {
      height: 75px;
      margin-bottom: 10px;
      border-radius: 0;
      font-family: 'Lato', sans-serif;
      font-weight: 300;
    }


    #infotest {
      display: none;
    }

    input#send_message {
      background-color: #57e1ff;
      border-color: #57e1ff;
      color: #fff;
      text-align: right;
    }

    input#send_message:hover {
      background-color: #fff;
      color: #57e1ff;
    }

  }

  div#status,
  div#success,
  div#failure {
    display: none;
    position: relative;
    top: 0px;
    min-height: 320px;
    width: 90%;
    padding-top: 100px;
    color: #666;
    background-color: transparent;

    p {
      color: #757575;
    }

    h4 {
      color: #757575;
    }
  }

  div#errors {
    display: none;
    position: relative;
    top: 0;
    margin-bottom: 10px;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.6);
  }
}
