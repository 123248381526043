.RoundedCorners(@radius: 4px) {
  -webkit-border-radius: @radius;
  -moz-border-radius: @radius;
  border-radius: @radius;
}

.day {
  background: url("/images/day.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
}

.night {
  background: url("/images/night.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
}

.dawn {
  background: url("/images/dawn.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
}

div#refresh-modal {
  background-color: rgba(0, 0, 0, 0.7);

  .modal-header {
    //background-color: #ccc;
  }

  .modal-title {
    color: @smart-blue;
    size: 18px;
  }
}

.no-label {
  margin-top: 25px;
}

.uploadifive-button {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  border: 1px solid transparent;
  padding: 0px 12px 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

@media screen and (min-width: 768px) {
  .uploadifive-queue-item.complete {
    margin-left: 112px;
    margin-bottom: 5px;
  }
}

span.filename {
  font-size: 16px;
  font-style: italic;
  font-weight: 700;
}

.accordion {
  border-bottom: 1px solid #ccc;

  h6 {
    -webkit-border-top-left-radius: 5px;
    -webkit-border-top-right-radius: 5px;
    -moz-border-radius-topleft: 5px;
    -moz-border-radius-topright: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    cursor: pointer;
    border: 1px solid #ccc;
    border-bottom: none;
    color: #333;
    margin-top: 0;
    margin-bottom: 0;
  }

  .ui-accordion-header {
    padding: 12px;
    background: #fff;
  }

  .ui-accordion-header-icon {
    margin: 0 6px;
    float: left;
  }

  .ui-widget-content {
    border: 1px solid #ccc;
  }

  .ui-accordion-content {
    padding: 20px;
  }

  label {
    font-weight: 300;
    font-size: 12px;
  }
}

.bootstrap-datetimepicker-widget table td.day {
  background: #fff;
}

.bootstrap-datetimepicker-widget table td.active {
  background-color: @smart-blue;
}

/** Alerts **/
/* line 16, ../sass/partials/_components.scss */
.alerts {
  min-height: 10px;
  max-width: 100%;
  margin: 10px 20px;
}

/* line 20, ../sass/partials/_components.scss */
.alerts p {
  height: 35px;
  line-height: 35px;
  color: #fff;
  padding: 0 0 0 13px;
  margin: 5px 0;
  width: 99%;
  text-align: left;
}

/* line 29, ../sass/partials/_components.scss */
.alerts .error {
  background: #DE3737;
  border: #F74040 1px solid;
}

/* line 33, ../sass/partials/_components.scss */
.alerts .success {
  background: #6EC278;
  border: #84E08F 1px solid;
}

/* line 37, ../sass/partials/_components.scss */
.alerts i {
  float: right;
  margin: 0 10px 0 0;
  cursor: pointer;
  line-height: 36px;
}

