/* DATA PAGINATION */
div.pagination-bar {

  position: relative;
  margin-bottom: 4rem;

  nav {

    position: relative;

    text-align: center;
    min-height: 55px !important;

    ul.pagination {
      margin: 0px 0px 0px 0px;
      padding: 10px 0px 42px 0px;
    }

  }


}

/* END PAGINATION */
